<template>
  <b-container>
    <ct-centered-spinner v-if="!loaded" height="16.25rem" />
    <!-- Store IC in metadata column -->

    <div v-else class="monitoring-trademark-card">
      <monitoring-instructional-accordion
        :id="'trademarks-collapse'"
        :header="'Trademark Monitoring'"
        :instructional-text="instructionalContent"
      />
      <div v-if="trademarks?.length">
        <b-table :items="trademarks" :fields="fields">
          <template v-slot:cell(active)="data">
            <div class="active-button">
              <b-form-checkbox
                v-model="data.item.active"
                name="check-button"
                switch size="lg"
                :disabled="!isEditMode || currentEditItem?.id !== data.item.id"
              />
            </div>
          </template>
          <template v-slot:head(value)>
            Mark Literal
          </template>
          <template v-slot:cell(value)="data">
            <b-form-input v-model="data.item.value" :disabled="!isEditMode || currentEditItem?.id !== data.item.id" />
          </template>
          <template v-slot:cell(international_class)="data">
            <b-form-input
              v-model="data.item.international_class"
              :disabled="!isEditMode || currentEditItem?.id !== data.item.id"
              :state="tableValidation[data.item.id]?.icInvalid ? false : null"
              :aria-describedby="'new-international-class-feedback-' + data.item.id"
              @input="handleTableIcInput(data.item)"
            />
            <b-form-invalid-feedback
              v-if="tableValidation[data.item.id]?.icInvalid"
              :id="'new-international-class-feedback-' + data.item.id"
            >
              Must be 3 digits
            </b-form-invalid-feedback>
          </template>
          <template v-slot:head(global_id)>
            Serial Number
          </template>
          <template v-slot:cell(global_id)="data">
            <b-form-input
              v-model="data.item.global_id"
              :disabled="!isEditMode || currentEditItem?.id !== data.item.id"
              :state="tableValidation[data.item.id]?.globalIdInvalid ? false : null"
              :aria-describedby="'new-serial-number-feedback-' + data.item.id"
              @input="handleTableGlobalIdInput(data.item)"
            />
            <b-form-invalid-feedback
              v-if="tableValidation[data.item.id]?.globalIdInvalid"
              :id="'new-serial-number-feedback-' + data.item.id"
            >
              Must be 8 digits
            </b-form-invalid-feedback>
          </template>
          <template v-slot:cell(actions)="data">
            <div class="manage-buttons">
              <b-button
                v-if="isEditMode && currentEditItem?.id === data.item.id"
                aria-label="Save button"
                variant="primary"
                size="sm"
                class="save-button"
                :disabled="!canSave(data.item)"
                @click="saveChanges(data.item)"
              >
                Save
              </b-button>
              <b-button
                v-if="!isEditMode || currentEditItem?.id !== data.item.id"
                aria-label="Edit button"
                variant="primary"
                size="sm"
                class="edit-button"
                @click="editItem(data.item)"
              >
                Edit
              </b-button>
              <b-button
                v-if="isEditMode && currentEditItem?.id === data.item.id"
                aria-label="Cancel button"
                variant="secondary"
                size="sm"
                class="cancel-button"
                @click="cancelEdit(data.item)"
              >
                Cancel
              </b-button>
              <b-button
                v-if="!isEditMode || currentEditItem?.id !== data.item.id"
                aria-label="Delete button"
                variant="danger" size="sm" class="delete-button"
                @click="toggleModal('confirmDelete', { itemToDelete: data.item })"
              >
                Remove
              </b-button>
            </div>
          </template>
        </b-table>

        <div>
          <div class="add-trademark-buttons">
            <div v-if="formattedOwnedData?.length" class="add-owned-item-button">
              <b-button variant="success" @click="toggleModal('addOwnedItem', refreshNewItem)">
                Add Trademark from your account
              </b-button>
            </div>
            <b-button variant="success" @click="toggleModal('addAction', refreshNewItem)">
              Add external Trademark
            </b-button>
          </div>
        </div>
      </div>

      <monitoring-no-identity v-else identity-type="trademarks">
        <div class="add-trademark-buttons">
          <div v-if="formattedOwnedData?.length" class="add-owned-item-button">
            <b-button variant="success" @click="toggleModal('addOwnedItem', refreshNewItem)">
              Add Trademark from your account
            </b-button>
          </div>
          <b-button variant="success" @click="toggleModal('addAction', refreshNewItem)">
            Add external Trademark
          </b-button>
        </div>
      </monitoring-no-identity>
    </div>

    <b-modal v-model="modalVisibility.addOwnedItem" hide-footer title="Add Trademark from Account">
      <b-form @submit.prevent="addItem">
        <b-form-group>
          <b-form-select v-model="newItem" :options="formattedOwnedData" />
        </b-form-group>
        <b-button type="submit" variant="primary" class="mr-2" aria-label="Add button">Add</b-button>
        <b-button variant="danger" aria-label="Cancel button" @click="toggleModal('addOwnedItem', { newItem: {} })">
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <b-modal v-model="modalVisibility.addAction" hide-footer title="Add Trademark(s)">
      <b-form @submit.prevent="addItem">
        <b-form-group>
          <b-form-input
            v-model="newItem.global_id"
            class="mb-2"
            placeholder="Existing Trademark Serial Number"
            :state="showGlobalIdValidation ? false : null"
            :disabled="!!newItem.international_class || !!newItem.value"
            aria-describedby="external-serial-number-feedback"
            @input="handleGlobalIdInput"
          />
          <b-form-invalid-feedback v-if="showGlobalIdValidation" id="external-serial-number-feedback">
            Must be 8 digits
          </b-form-invalid-feedback>
          OR
          <b-form-input
            v-model="newItem.value"
            :disabled="!!newItem.global_id"
            class="mb-2"
            placeholder="Trademark Mark Literal"
          />
          <b-form-input
            v-model="newItem.international_class"
            :state="showIcValidation ? false : null"
            :disabled="!!newItem.global_id"
            placeholder="International Class"
            aria-describedby="external-international-class-feedback"
            @input="handleIcInput"
          />
          <b-form-invalid-feedback v-if="showIcValidation" id="external-international-class-feedback">
            Must be 3 digits
          </b-form-invalid-feedback>
        </b-form-group>
        <b-button type="submit" variant="primary" class="mr-2" aria-label="Add button" :disabled="!canAdd"
                  @click="validateInputs"
        >
          Add
        </b-button>
        <b-button variant="danger" aria-label="Cancel button" @click="toggleModal('addAction', { saveItem: {} })">
          Cancel
        </b-button>
      </b-form>
    </b-modal>
    <b-modal v-model="modalVisibility.confirmDelete" hide-footer title="Are you sure you want to delete?">
      <b-button class="mt-3" variant="secondary" block aria-label="Cancel button"
                @click="toggleModal('confirmDelete', { itemToDelete: {} })"
      >
        Cancel
      </b-button>
      <b-button class="mt-2" variant="danger" block aria-label="Confirm button" @click="deleteItem">Confirm</b-button>
    </b-modal>
  </b-container>
</template>

<script>
import MonitoringInstructionalAccordion from '@/components/Monitoring/MonitoringInstructionalAccordion.vue'
import MonitoringNoIdentity from '@/components/Monitoring/MonitoringNoIdentity.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { MonitoringIdentityType } from '@/common/modules/constants'
import { mapGetters, mapActions } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'MonitoringTrademarksPage',
  components: {
    MonitoringNoIdentity,
    CtCenteredSpinner,
    MonitoringInstructionalAccordion,
  },
  mixins: [makeToastMixin],
  data() {
    return {
      fields: ['active', 'value', 'global_id', 'international_class', 'actions'],
      newItem: { active: true, value: '', global_id: '', international_class: '' },
      itemType: '',
      refreshNewItem: { newItem: { value: '', active: true, type: this.itemType, meta: { ics: '' }, global_id: '', international_class: '' } },
      loaded: false,
      trademarks: [],
      ownedData: [],
      modalVisibility: {
        addAction: false,
        confirmDelete: false,
        addOwnedItem: false,
      },
      isEditMode: false,
      currentEditItem: null,
      instructionalContent: {
        sections: [
          {
            header: 'Set up a Trademark Identity to monitor:',
          },
          {
            generic: 'You have three options for adding a trademark to monitor',
            items: [
              '<strong>Select from trademarks in your account with us.</strong> Click “Add Trademark from your account” below to see the list of trademark serial numbers that our company has filed for you. Selecting the preferred trademark from the list ensures you\'re monitoring trademarks on record with us.',
              '<strong>Enter your Serial Number.</strong> Click “Add external Trademark” below, and enter the serial number. We\'ll populate the mark literal and International Class.',
              '<strong>Enter your mark literal and International Class (IC):</strong> Click “Add external Trademark” below, and enter the mark literal and IC.',
            ],
          },
          {
            generic: 'To look up your trademark status and informational documents, visit the <a href="https://tsdr.uspto.gov/#caseNumber=&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch">USPTO Trademark Status & Document Retrieval</a> page.',
          },
        ],
      },
      showGlobalIdValidation: false,
      showIcValidation: false,
      tableValidation: {},
    }
  },
  computed: {
    ...mapGetters('monitoring', [
      'monitoringCandidates',
      'monitoringIdentitiesByType',
    ]),
    ...mapGetters('account', ['actualAccountID']),
    isGlobalIdValid() {
      return !this.newItem.global_id || /^[0-9]{8}$/.test(this.newItem.global_id)
    },
    isIcValid() {
      return !this.newItem.international_class || /^[0-9]{3}$/.test(this.newItem.international_class)
    },
    buttonText() {
      return this.isEditMode ? 'Save' : 'Edit'
    },
    formattedOwnedData() {
      return this.ownedData?.filter(d => !this.trademarks.some(tm => tm.global_id === d.global_id))
      .map(d => ({
        text: d.global_id,
        value: d,
      }))
    },
    canAdd() {
      return (
        (!!this.newItem.global_id && /^[0-9]{8}$/.test(this.newItem.global_id)) ||
        (!!this.newItem.value && /^[0-9]{3}$/.test(this.newItem.international_class))
      )
    },
  },
  async mounted() {
    this.loaded = false

    this.itemType = MonitoringIdentityType.TRADEMARK[0]
    await this.fetchMonitoringIdentitiesByType({ accountID: this.actualAccountID, identityType: this.itemType })
    await this.fetchRecords()
    this.parseTrademarkMetadata()
    this.refreshNewItem.newItem.type = this.itemType

    this.loaded = true
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringCandidates',
      'fetchMonitoringIdentitiesByType',
      'removeMonitoringIdentity',
      'updateMonitoringIdentity',
    ]),
    ...mapActions('companies', ['loadMore']),
    toggleModal(modalKey, options = {}) {
      if (this.modalVisibility.hasOwnProperty(modalKey)) {
        this.modalVisibility[modalKey] = !this.modalVisibility[modalKey]
        Object.keys(options).forEach(key => this[key] = options[key])
        this.resetValidation()
      }
    },
    canSave(item) {
      if (!item.global_id && !item.value && !item.international_class) return false               // all values are empty
      if (!!item.global_id && !/^[0-9]{8}$/.test(item.global_id)) return false                     // serial number is present and invalid
      if (!!item.international_class && !/^[0-9]{3}$/.test(item.international_class)) return false // IC is present and invalid
      if (!item.global_id && (!item.international_class || !item.value)) return false              // serial number and IC or literal are empty
      return true
    },
    resetValidation() {
      this.showGlobalIdValidation = false
      this.showIcValidation = false
      this.newItem = { active: true, value: '', global_id: '', international_class: '' }
    },
    validateInputs() {
      let valid = true
      if (!this.isGlobalIdValid && (!this.newItem.international_class || !this.newItem.value)) {
        this.showGlobalIdValidation = true
        valid = false
      } else {
        this.showGlobalIdValidation = false
      }

      if (!this.isIcValid && !this.newItem.global_id) {
        this.showIcValidation = true
        valid = false
      } else {
        this.showIcValidation = false
      }
      return valid
    },
    handleGlobalIdInput() {
      this.showGlobalIdValidation = !this.isGlobalIdValid && !!this.newItem.global_id
    },
    handleIcInput() {
      this.showIcValidation = !this.isIcValid && !!this.newItem.international_class
    },
    isGlobalIdValidForItem(item) {
      return /^[0-9]{8}$/.test(item.global_id)
    },
    isIcValidForItem(item) {
      return /^[0-9]{3}$/.test(item.international_class)
    },
    validateTableRow(item) {
      const globalIdValid = this.isGlobalIdValidForItem(item)
      const icValid = this.isIcValidForItem(item)

      this.$set(this.tableValidation, item.id, {
        globalIdInvalid: !globalIdValid && !!item.global_id,
        icInvalid: !icValid && !!item.international_class,
      })

      return (globalIdValid || (item.value && icValid)) && !((!globalIdValid && !!item.global_id) || (!icValid && !!item.international_class))
    },
    handleTableGlobalIdInput(item) {
      this.validateTableRow(item)
    },
    handleTableIcInput(item) {
      this.validateTableRow(item)
    },
    parseTrademarkMetadata() {
      let trademarkData = this.monitoringIdentitiesByType[this.itemType]
      this.trademarks = trademarkData?.map(t => {
        t.international_class = t.meta?.ics
        return t
      })
    },
    async fetchRecords() {
      this.loaded = false
      await this.fetchMonitoringCandidates()

      this.ownedData = this.monitoringCandidates?.map((candidate) => {
        return {
          active: true,
          global_id: candidate.data['uspto_serial'],
          value: candidate.data['literal'] || '',
          type: this.itemType,
          international_class: candidate.data['international_class'],
        }

      })
    },
    async addItem() {
      if (!this.validateInputs()) return

      try {
        const itemCopy = {
          id: uuidv4(),
          account_id: this.actualAccountID,
          type: this.newItem.type,
          value: this.newItem.value,
          active: this.newItem.active,
          global_id: this.newItem.global_id,
          meta: { ics: this.newItem.international_class },
        }

        await this.updateMonitoringIdentity(itemCopy)
        itemCopy.international_class = itemCopy.meta.ics
        this.trademarks.push(itemCopy)
        this.successToast('Success', 'Trademark added')
      } catch (error) {
        this.errorToast('Error', `Failed to add trademark - ${error.error}`)
      } finally {
        this.resetValidation()
        const resetItemList = ['addOwnedItem', 'addAction']
        const resetNewItem = { newItem: {} }

        resetItemList.map(item => {
          if (this.modalVisibility[item]) {
            this.toggleModal(item, resetNewItem)
          }
        })
      }
    },
    editItem(item) {
      this.isEditMode = true
      this.currentEditItem = { ...item } // Copy of the item being edited
      this.originalItem = { ...item } // Original item to revert changes if needed
    },
    async saveChanges(item) {
      if (!this.isEditMode) return
      if (!this.validateTableRow(item)) return

      try {
        let itemCopy = { ...item } // Shallow copy to avoid adjusting base value
        itemCopy.meta.ics = item.international_class
        await this.updateMonitoringIdentity(itemCopy)
        this.successToast('Success', "Trademark saved")
        this.isEditMode = false
        this.currentEditItem = null
        this.resetTableValidation()
      } catch (error) {
        this.errorToast('Error', `Failed to save trademark - ${error.error}`)
      }
    },
    cancelEdit(item) {
      this.isEditMode = false
      Object.assign(item, this.originalItem) // Revert changes to the original item
      this.currentEditItem = null
      this.resetTableValidation()
    },
    resetTableValidation() {
      this.tableValidation = {}
    },
    async deleteItem() {
      let item = this.itemToDelete
      try {
        await this.removeMonitoringIdentity({ monitoringIdentityID: item.id, accountID: this.actualAccountID })
        const index = this.trademarks.findIndex(trademark => trademark.id === item.id)
        if (index > -1) {
          this.trademarks.splice(index, 1)
        }
        this.successToast('Success', "Trademark deleted")
      } catch {
        this.errorToast('Error', "Trademark failed to delete, please try again")
      } finally {
        this.toggleModal('confirmDelete', { itemToDelete: {} })
      }
    },
  },
}
</script>

<style lang="scss">
.monitoring-trademark-card {
  .table {
    border: 1px solid #cccee0;
    border-radius: 0.5em;
    background-color: white;
  }

  .table td:first-child {
    vertical-align: middle;
  }

  .table .custom-switch {
    padding-bottom: 1rem;
  }

  .table td:nth-child(2) {
    width: 30rem;
  }

  .table td:nth-child(3) {
    width: 10rem;
  }

  .add-trademark-buttons {
    display: flex;
    justify-content: center;
    margin: 2em;
    gap: 2em;
  }

  .add-trademark-buttons button {
    width: 22rem;
  }
}


.expand-link {
  display: inline-block;
  color: rgb(0, 123, 255) !important;
  cursor: pointer;
}

.invalid-serial {
  color: red;
}

.active-button {
  margin-top: .6rem;
  margin-left: .5rem;
}

.expand-link {
  display: inline-block;
  color: rgb(0, 123, 255) !important;
  cursor: pointer;
}

.add-owned-item-button {
  text-align: right;
}

.manage-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-right: 1rem;
  max-width: 7rem;
}

.manage-buttons>button {
  width: 100%;
  margin: 0 !important;
}
</style>
